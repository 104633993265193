import React from 'react'
import styled from '@emotion/styled'

const Container = styled.li`
  display: flex;
  align-items: center;
`

const AvatarContainer = styled.div`
  display: inline-block;
  position: relative;
  margin-right: 22px;
`

const Avatar = styled.img`
  border-radius: 999px;
  border: solid 1px ${props => props.theme.colours.black};
`

const Tick = styled.div`
  background: ${props => props.theme.colours.white};
  border-radius: 999px;
  color: ${props => props.theme.colours.success};
  height: 28px;
  padding: 3px;
  position: absolute;
  right: -10px;
  top: -1px;
  width: 28px;
`

const User = ({ as, avatarUrl, name, ticked }) => (
  <Container as={as}>
    <AvatarContainer>
      <Avatar src={avatarUrl} />
      {ticked && (
        <Tick>
          <svg fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
              fill-rule="evenodd"
            ></path>
          </svg>
        </Tick>
      )}
    </AvatarContainer>
    {name}
  </Container>
)

export default User
