import styled from '@emotion/styled'

const CardGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  column-gap: 20px;
  row-gap: 25px;

  @media (min-width: 726px) {
    column-gap: 50px;
    row-gap: 55px;
  }
`

export default CardGrid
