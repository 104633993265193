import React from 'react'
import styled from '@emotion/styled'

const Heading = styled.h1`
  ${props => `
  font-family: ${props.theme.fonts.serif};
  font-size: ${props.theme.typography.headingSizes[props.size || 1]}px;
  font-weight: normal;
  line-height: 1.33;
  color: ${props.theme.colours.black};
  display: ${props.inline || props.underline ? 'inline-block' : 'block'};
  position: relative;

  ${
    props.underline
      ? `
  &:after {
    position: absolute;
    display: block;
    content: '';
    height: 20px;
    background: ${props.theme.colours.fieryRose};
    opacity: 0.5;
    width: 102%;
    bottom: -4px;
  }
  `
      : ''
  }
`}
`

export default Heading
