import React, { useEffect } from 'react'
import { useMutation } from 'urql'

import SignInForm from '../components/SignInForm'
import SEO from '../components/seo'

const InsertUserMutation = `
  mutation InsertUserMutation($name: String!) {
    user: insert_users_one(object: { name: $name }) {
      id
    }
  }
`

const SignInContainer = () => {
  const [{ error, fetching, data }, insertUser] = useMutation(
    InsertUserMutation
  )

  useEffect(() => {
    if (process.browser && data?.user?.id) {
      window.localStorage.setItem('revote_user_id', data.user.id)
      window.location.reload()
    }
  }, [data?.user?.id])

  return (
    <>
      <SEO title="Who are you?" />{' '}
      {error ? (
        'Oops!'
      ) : fetching ? (
        'Loading...'
      ) : (
        <SignInForm onSubmit={insertUser} />
      )}
    </>
  )
}

export default SignInContainer
