import React, { useEffect } from 'react'
import { useMutation } from 'urql'

import CreateRoomForm from '../components/CreateRoomForm'
import SEO from '../components/seo'

const InsertRoomMutation = `
  mutation InsertRoomMutation($name: String!, $ownerId: uuid!) {
    room: insert_rooms_one(object: { name: $name, owner_id: $ownerId }) {
      id
    }
  }
`

const InsertTopicMutation = `
  mutation InsertTopicMutation($name: String!, $roomId: uuid!) {
    topic: insert_topics_one(object: { name: $name, room_id: $roomId }) {
      id
    }
  }
`

const CreateRoomContainer = ({ userId, navigate }) => {
  const [createRoomResult, createRoom] = useMutation(InsertRoomMutation)
  const [createTopicResult, createTopic] = useMutation(InsertTopicMutation)

  const handleSubmit = async ({ name }) => {
    const roomResult = await createRoom({ name, ownerId: userId })
    if (roomResult.error || !roomResult.data) return
    const topicResult = await createTopic({
      roomId: roomResult.data.room?.id,
      name: 'Topic',
    })
    if (topicResult.error || !topicResult.data) return
    console.log({ roomResult, topicResult })
    navigate(`/room/${roomResult.data.room?.id}`)
  }

  return (
    <>
      <SEO title="Create room" />
      {createRoomResult.error || createTopicResult.error ? (
        'Oops!'
      ) : createRoomResult.fetching || createTopicResult.fetching ? (
        'Loading...'
      ) : (
        <CreateRoomForm onSubmit={handleSubmit} />
      )}
    </>
  )
}

export default CreateRoomContainer
