import React from 'react'
import styled from '@emotion/styled'

import Button from '../Button'
import CardButton from '../CardButton'
import CardGrid from '../CardGrid'
import Heading from '../Heading'
import User from '../User'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const VotingSection = styled.section`
  flex-grow: 3;
  max-width: 890px;

  h1 {
    margin-bottom: 60px;
  }

  > h2:last-of-type {
    ${props => props.theme.utility.visuallyHidden}
  }

  @media (min-width: 810px) {
    h1 {
      margin-bottom: 120px;
    }
  }
`

const ParticipantsSection = styled.section`
  background: #e5e5e5;
  flex-grow: 1;
  padding: 60px 56px 56px;

  h2 {
    margin-bottom: 28px;
  }

  @media (min-width: 810px) {
    margin-left: 85px;
    padding-top: 190px;
  }
`

const ParticipantsList = styled.ul`
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 20px;
  }
`

const Room = ({
  name,
  users = [],
  userId,
  onVote,
  topicName,
  isComplete,
  onComplete,
  currentVote,
  isVoting,
  votes = [],
}) => (
  <Container>
    <VotingSection>
      <Heading underline>{name}</Heading>
      {isComplete && (
        <>
          <Heading as="h2" size={3}>
            Vote average
          </Heading>
          <p>
            {(
              votes.reduce(
                (acc, curr) =>
                  acc + Number(curr.value === '?' ? 0 : curr.value),
                0
              ) / votes.length
            ).toFixed(2)}
          </p>
        </>
      )}
      <Heading as="h2" size={3}>
        Voting cards
      </Heading>
      <CardGrid as="ul">
        {['1', '2', '3', '5', '8', '13', '21', '?'].map(value => (
          <li key={value}>
            <CardButton
              disabled={isVoting || isComplete}
              onClick={() => onVote(value)}
              selected={currentVote === value}
              value={value}
              key={value}
            />
          </li>
        ))}
      </CardGrid>
    </VotingSection>
    <ParticipantsSection>
      <Heading as="h2" size={3}>
        Participants
      </Heading>
      <ParticipantsList>
        {users.map(user => (
          <User
            key={user.id}
            avatarUrl={`https://api.adorable.io/avatars/62/${user.name}`}
            name={user.name + (user.id === userId && ' (You)')}
            ticked={!isComplete && user.hasVoted}
          >
            {isComplete
              ? ` ${votes.find(v => v.user_id === user.id)?.value || ''}`
              : user.hasVoted && ' (Voted)'}
          </User>
        ))}
      </ParticipantsList>
      <Button onClick={onComplete} disabled={isComplete}>
        Reveal votes
      </Button>
    </ParticipantsSection>
  </Container>
)

export default Room
