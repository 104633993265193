import React, { useEffect } from 'react'
import { useQuery, useMutation, useSubscription } from 'urql'

import Room from '../components/Room'
import SEO from '../components/seo'

const RoomSubscription = `
  subscription RoomById($roomId: uuid!) {
    rooms_by_pk(id: $roomId) {
      id
      name
      topics {
        id
        is_complete
        name
        votes {
          id
          user_id
          value
        }
      }
      user_rooms {
        user {
          id
          name
        }
      }
    }
  }
`

const InsertUserRoomMutation = `
  mutation InsertUserRoomMutation($userId: uuid!, $roomId: uuid!) {
    insert_user_rooms_one(object: { user_id: $userId, room_id: $roomId }) {
      id
    }
  }
`

const InsertVoteMutation = `
  mutation InsertVoteMutation($userId: uuid!, $topicId: uuid!, $value: String!) {
    insert_votes_one(object: { user_id: $userId, topic_id: $topicId, value: $value }) {
      id
    }
  }
`

const UpdateVoteMutation = `
  mutation UpdateVoteMutation($voteId: uuid!, $value: String!) {
    update_votes_by_pk(pk_columns: { id: $voteId }, _set: {value: $value}) {
      id
    }
  }
`

const UpdateTopicMutation = `
  mutation UpdateTopicMutation($topicId: uuid!, $isComplete: Boolean!) {
    update_topics_by_pk(pk_columns: { id: $topicId }, _set: { is_complete: $isComplete }) {
      id
    }
  }
`

const handleSubscription = (_, response) => response

const RoomContainer = ({ roomId, userId }) => {
  const [{ data, error }] = useSubscription(
    {
      query: RoomSubscription,
      variables: { roomId },
    },
    handleSubscription
  )

  const [{ fetching: joiningRoom }, joinRoom] = useMutation(
    InsertUserRoomMutation
  )
  const [{ fetching: votingOnTopic }, voteOnTopic] = useMutation(
    InsertVoteMutation
  )
  const [{ fetching: updatingVote }, updateVote] = useMutation(
    UpdateVoteMutation
  )
  const [{ fetching: updatingTopic }, updateTopic] = useMutation(
    UpdateTopicMutation
  )

  useEffect(() => {
    if (
      !error &&
      data?.rooms_by_pk?.user_rooms?.every(({ user }) => user.id !== userId)
    ) {
      joinRoom({ userId, roomId })
    }
  }, [userId, roomId, data, error])

  if (error) return 'Oops!'
  if (!data) return 'Loading...'
  if (joiningRoom) return 'Joining...'

  const room = data.rooms_by_pk

  if (!room) return 'What room?'

  console.log({ room })

  const topic = room.topics[0]

  const currentVote = topic.votes.find(v => v.user_id === userId)

  console.log(currentVote)

  return (
    <>
      <SEO title={room.name} />
      <Room
        name={room.name}
        users={room.user_rooms.map(u => ({
          ...u.user,
          hasVoted: topic.votes.some(v => v.user_id === u.user.id),
        }))}
        isVoting={votingOnTopic || updatingVote || updatingTopic}
        isComplete={topic.is_complete}
        onVote={value =>
          currentVote
            ? updateVote({ voteId: currentVote.id, value })
            : voteOnTopic({ topicId: topic.id, userId, value })
        }
        currentVote={topic.votes.find(v => v.user_id === userId)?.value}
        userId={userId}
        votes={topic.votes}
        onComplete={() => updateTopic({ topicId: topic.id, isComplete: true })}
      />
    </>
  )
}

export default RoomContainer
