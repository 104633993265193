import React from 'react'
import { Formik, ErrorMessage } from 'formik'
import styled from '@emotion/styled'
import * as yup from 'yup'

import Button from './Button'
import Heading from './Heading'

const Input = styled.input`
  margin-bottom: 38px;
  padding: 12px;
  border-radius: 4px;
  border: solid 1px ${props => props.theme.colours.lightGrey};
`

const CreateRoomSchema = yup.object().shape({
  name: yup.string().required('Required'),
})

const CreateRoomForm = ({ onSubmit }) => {
  return (
    <Formik
      initialValues={{ name: '' }}
      validationSchema={CreateRoomSchema}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {props => (
        <form onSubmit={props.handleSubmit}>
          <Heading size={2}>
            <label htmlFor="name">What's the name of the room?</label>
          </Heading>
          <br />
          <Input
            id="name"
            name="name"
            type="text"
            onChange={props.handleChange}
            value={props.values.name}
            placeholder="Sprint Planning"
          />
          <ErrorMessage name="name" />
          <br />
          <Button type="submit">Create room</Button>
        </form>
      )}
    </Formik>
  )
}

export default CreateRoomForm
