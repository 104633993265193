import React from 'react'
import styled from '@emotion/styled'

const Card = styled.button`
  ${props => `
    appearance: none;
    background: ${props.theme.colours.white};
    border-radius: 6px;
    border: solid 1px rgba(255, 65, 108, 0.51);
    box-shadow: -2px 2px 4px rgba(151, 151, 151, 0.4);
    color: ${props.theme.colours.fieryRose};
    cursor: pointer;
    font-family: ${props.theme.fonts.sansSerif};
    font-size: 24px;
    line-height: 24px;
    max-width: 150px;
    max-height: 190px;
    overflow: hidden;
    padding: 37px 30px;
    position: relative;

    ${
      props.selected
        ? `
      background: linear-gradient(
        143.23deg,
        ${props.theme.colours.fieryRose} 0%,
        #ff4552 40.67%,
        ${props.theme.colours.burningOrange} 100%
      );
      border: none;
      color: ${props.theme.colours.white};
    `
        : ''
    }

    > span {
      position: absolute;

      &:first-child {
        top: 8px;
        left: 8px;
      }

      &:last-child {
        bottom: 8px;
        right: 8px;
        transform: rotate(180deg);
      }
    }
  `}
`

const Value = styled.div`
  ${props => `
  background: linear-gradient(
    143.23deg,
    ${props.theme.colours.fieryRose} 0%,
    #ff4552 40.67%,
    ${props.theme.colours.burningOrange} 100%
  );
  border-radius: 4px;
  color: ${props.theme.colours.white};
  font-family: ${props.theme.fonts.cursive};
  font-size: ${props.smallText ? '124' : '192'}px;
  height: 116px;
  line-height: 48px;
  padding-top: 20px;
  position: relative;
  overflow: ${props.selected ? 'visible' : 'hidden'};
  width: 90px;
  white-space: nowrap;

  span {
    position: absolute;
    white-space: nowrap;
    left: ${props.smallText ? '-12' : '0'}px;
  }
`}
`

const CardButton = ({ value, selected, ...props }) => (
  <Card selected={selected} {...props}>
    <span>{value}</span>
    <Value
      smallText={typeof value === 'string' ? value.length > 1 : value > 9}
      selected={selected}
    >
      <span>{value}</span>
    </Value>
    <span>{value}</span>
  </Card>
)

export default CardButton
