import React from 'react'
import { Router } from '@reach/router'
import {
  createClient,
  Provider,
  dedupExchange,
  fetchExchange,
  subscriptionExchange,
} from 'urql'
import { SubscriptionClient } from 'subscriptions-transport-ws'

import Layout from '../components/layout'
import CreateRoomContainer from '../containers/CreateRoomContainer'
import RoomContainer from '../containers/RoomContainer'
import SignInContainer from '../containers/SignInContainer'

const subscriptionClient = process.browser
  ? new SubscriptionClient('wss://revote-hasura.herokuapp.com/v1/graphql', {
      reconnect: true,
      connectionParams: {
        headers: {
          'x-hasura-admin-secret': process.env.GATSBY_GRAPHQL_ADMIN_SECRET,
        },
      },
    })
  : null

const client = createClient({
  url: process.env.GATSBY_GRAPHQL_ENDPOINT,
  exchanges: [
    dedupExchange,
    fetchExchange,
    subscriptionExchange({
      forwardSubscription: operation => subscriptionClient.request(operation),
    }),
  ],
  fetchOptions: {
    headers: {
      'x-hasura-admin-secret': process.env.GATSBY_GRAPHQL_ADMIN_SECRET,
    },
  },
})

const App = () => {
  const userId = process.browser
    ? window.localStorage.getItem('revote_user_id')
    : null

  if (!userId)
    return (
      <Provider value={client}>
        <Layout>
          <SignInContainer />
        </Layout>
      </Provider>
    )

  return (
    <Provider value={client}>
      <Layout showHeader={false}>
        <Router basepath="/room">
          <RoomContainer path="/:roomId" userId={userId} />
          <CreateRoomContainer path="/" userId={userId} />
        </Router>
      </Layout>
    </Provider>
  )
}

export default App
